import { Col, Flex, Input, Row, Select, Typography } from "antd";
import React, { useMemo, useState } from "react";
import Fuse from 'fuse.js';
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { getAllDcmCodes } from "../../service/client.service";
import { setClientForm, setDsm } from "../../slices/clientSlice";
import SelectableBadge from "../Badge/SelectableBadge.component";
import styles from "./clientform.module.scss";
import { CATEGORY, GENDER_TYPE, LANGUAGE_TYPE, REFER_TYPE } from "../../domain/notes.domain";

const ClientForm = () => {
  const componentName = "clientform";
  const dispatch = useAppDispatch();
  const { clientForm, dsm } = useAppSelector(
    (state) => state.client,
  );
  const [searchTerm, setSearchTerm] = useState('');

  const updateClientForm = 
    (key: string, value: any) => {
      dispatch(setClientForm({ ...clientForm, [key]: value }));
  };

  const fuse = useMemo(() => new Fuse(dsm, {
    keys: ['name', 'code'],
    threshold: 0.3,
    ignoreLocation: true,
    useExtendedSearch: true,
  }), [dsm]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: no need to dsm as a dependency
  const filteredOptions = useMemo(() => {
    if (!searchTerm) return dsm;
    return fuse.search(searchTerm).map(result => result.item);
  }, [fuse, searchTerm]);

  const getDsmCodes = () => {
    getAllDcmCodes()
      .then((response) => {
        dispatch(setDsm(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // biome-ignore lint: no need to add getDsmCodes to the dependency array
  React.useEffect(() => {
    getDsmCodes();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Typography.Text className={`${styles[`${componentName}-form-label`]}`}>
          Name:
        </Typography.Text>
        <Input
          defaultValue={clientForm.name}
          onBlur={(e) => updateClientForm("name", e.target.value)}
          placeholder="eg. John Doe or JD"
          className={`${styles[`${componentName}-form-label`]}`}
        />
      </Col>
      <Col span={24} className={`${styles[`${componentName}__modal-row`]}`}>
        <Typography.Text className={`${styles[`${componentName}-form-label`]}`}>
          Category:
        </Typography.Text>
        <div className={`${styles[`${componentName}-form-input`]}`}>
          <Flex>
            <SelectableBadge
              count="Individual"
              selected={clientForm.category_type === CATEGORY.INDIVIDUAL}
              onClick={() => updateClientForm("category_type", CATEGORY.INDIVIDUAL)}
            ></SelectableBadge>
            <SelectableBadge
              count="Relationship"
              selected={clientForm.category_type === CATEGORY.RELATIONSHIP}
              onClick={() => updateClientForm("category_type", CATEGORY.RELATIONSHIP)}
            ></SelectableBadge>
            <SelectableBadge
              count="Family"
              selected={clientForm.category_type === CATEGORY.FAMILY}
              onClick={() => updateClientForm("category_type", CATEGORY.FAMILY)}
            ></SelectableBadge>
            <SelectableBadge
              count="Group"
              selected={clientForm.category_type === CATEGORY.GROUP}
              onClick={() => updateClientForm("category_type", CATEGORY.GROUP)}
            ></SelectableBadge>
          </Flex>
        </div>
      </Col>
      <Col span={24}>
        <Typography.Text className={`${styles[`${componentName}-form-label`]}`}>
          Pronouns:
        </Typography.Text>{" "}
        <br />
        <div className={`${styles[`${componentName}-form-input`]}`}>
          <Flex>
            <SelectableBadge
              count="Neutral"
              selected={clientForm.gender_type === GENDER_TYPE.NEUTRAL}
              onClick={() => {
                updateClientForm("gender_type", GENDER_TYPE.NEUTRAL);
              }}
            />
            <SelectableBadge
              count="He/Him"
              selected={clientForm.gender_type === GENDER_TYPE.MALE}
              onClick={() => {
                updateClientForm("gender_type", GENDER_TYPE.MALE);
              }}
            />
            <SelectableBadge
              count="She/Her"
              selected={clientForm.gender_type === GENDER_TYPE.FEMALE}
              onClick={() => {
                updateClientForm("gender_type", GENDER_TYPE.FEMALE);
              }}
            />
          </Flex>
        </div>
      </Col>
      <Col span={24}>
        <Typography.Text className={`${styles[`${componentName}-form-label`]}`}>
          Diagnoses:
        </Typography.Text>
        <br />
        <Select
          mode="multiple"
          value={clientForm.diagnosis_ids}
          placeholder="Select all diagnoses"
          placement="bottomLeft"
          onChange={(values) => {
            updateClientForm("diagnosis_ids", values);
          }}
          className={`${styles[`${componentName}-form-input`]}`}
          onSearch={setSearchTerm}
          filterOption={false}
          options={filteredOptions.map((item) => ({
            value: item.id,
            label: `${item.name} (${item.code})`,
          }))}
          dropdownRender={(menu) => <div className="client">{menu}</div>}
        />
      </Col>
      <Col span={24}>
        <Typography.Text className={`${styles[`${componentName}-form-label`]}`}>
          Refer to client as:
        </Typography.Text>
        <br />
        <div className={`${styles[`${componentName}-form-input`]}`}>
          <Flex>
            <SelectableBadge
              count="Client"
              selected={clientForm.refer_type === REFER_TYPE.CLIENT}
              onClick={() => {
                updateClientForm("refer_type", REFER_TYPE.CLIENT);
              }}
            ></SelectableBadge>
            <SelectableBadge
              count="Patient"
              selected={clientForm.refer_type === REFER_TYPE.PATIENT}
              onClick={() => {
                updateClientForm("refer_type", REFER_TYPE.PATIENT);
              }}
            ></SelectableBadge>
            <SelectableBadge
              count="Individual"
              selected={clientForm.refer_type === REFER_TYPE.INDIVIDUAL}
              onClick={() => {
                updateClientForm("refer_type", REFER_TYPE.INDIVIDUAL);
              }}
            ></SelectableBadge>
          </Flex>
        </div>
      </Col>
      <Col span={24}>
        <Typography.Text className={`${styles[`${componentName}-form-label`]}`}>
          Language:
        </Typography.Text>
        <br />
        <div className={`${styles[`${componentName}-form-input`]}`}>
          <Flex>
            <SelectableBadge
              count="English"
              selected={clientForm.language_type === LANGUAGE_TYPE.ENGLISH}
              onClick={() => {
                updateClientForm("language_type", LANGUAGE_TYPE.ENGLISH);
              }}
            ></SelectableBadge>
            <SelectableBadge
              count="Spanish"
              selected={clientForm.language_type === LANGUAGE_TYPE.SPANISH}
              onClick={() => {
                updateClientForm("language_type", LANGUAGE_TYPE.SPANISH);
              }}
            ></SelectableBadge>
          </Flex>
        </div>
      </Col>
    </Row>
  );
};

export default ClientForm;
