import { useCallback } from "react";
import { useAppSelector } from "./redux-hooks";
import { useIntercom } from "react-use-intercom";

const useIntercomWithData = () => {
  const user = useAppSelector((state) => state.user);
  const { boot, shutdown } = useIntercom();

  const bootWithData = useCallback(() => {
    boot({
      userId: user.data?.id,
      email: user.data?.email,
      name: user.data?.name,
    });
  }, [boot, user.data?.id, user.data?.email, user.data?.name]);

  return { bootWithData, shutdown };
};

export default useIntercomWithData;
